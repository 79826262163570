<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1 class="mb-0">Renewal Manage</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <validation-observer ref="formRef" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col>
                      <b-form-group label="Reference No." label-for="v-refnum">
                        <b-form-input
                          disabled
                          v-model="formData.refnum"
                          id="v-refnum"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Vendor" label-for="v-vendor">
                        <v-select
                          id="v-vendor"
                          v-model="formData.vendor"
                          :options="suppliers"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="First Purchase Date"
                        label-for="v-first_purchase_date"
                      >
                        <b-form-input
                          id="v-first_purchase_date"
                          v-model="previewFirstPurchaseDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Terminate Date"
                        label-for="v-termination_date"
                      >
                        <b-form-input
                          id="v-termination_date"
                          v-model="previewTerminationDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="formData.id === 0">
                    <b-col cols="12" md="4">
                      <b-form-group label="Start Date" label-for="v-on_date">
                        <b-form-input
                          id="v-on_date"
                          v-model="previewOnDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group label="End Date" label-for="v-due_date">
                        <b-form-input
                          id="v-due_date"
                          v-model="previewDueDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group
                        label="Reminder Date"
                        label-for="v-reminder_date"
                      >
                        <b-form-input
                          id="v-reminder_date"
                          v-model="previewReminderDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="formData.id === 0">
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Payment Date"
                        label-for="v-payment_date"
                      >
                        <b-form-input
                          id="v-payment_date"
                          v-model="previewPaymentDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Payment Amount" label-for="v-fee">
                        <cleave
                          id="v-fee"
                          name="fee"
                          v-model="watchFee"
                          class="form-control text-right"
                          :raw="false"
                          :options="options.feeCleave"
                          max="100"
                          min="0"
                          step="1"
                          maxlength="11"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Receipt No."
                        label-for="v-receipt_no"
                      >
                        <b-form-input
                          v-model="formData.receipt_no"
                          id="v-receipt_no"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Product Type"
                        label-for="v-product_type"
                      >
                        <v-select
                          id="v-product_type"
                          v-model="formData.product_type"
                          :options="productTypes"
                          :reduce="(pt) => pt.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Serial No." label-for="v-serial_no">
                        <b-form-input
                          v-model="formData.serial_no"
                          id="v-serial_no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Renewal Status"
                        label-for="v-renewal_status"
                      >
                        <b-form-select
                          id="v-renewal_status"
                          v-model="formData.renewal_status"
                          :options="renewalStatuses"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Method" label-for="v-method">
                        <b-form-input v-model="formData.method" id="v-method" />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Reminder" label-for="v-reminder">
                        <b-form-input
                          v-model="formData.reminder"
                          id="v-reminder"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Notification Email"
                        label-for="v-notification_email"
                      >
                        <b-form-input
                          v-model="formData.notification_email"
                          id="v-notification_email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="URL" label-for="v-url">
                        <b-form-input v-model="formData.url" id="v-url" />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Customer Information</h2>
                    </b-col>

                    <b-col class="text-right">
                      <CompanySearch @on-select="(v) => onSelectCompany(v)" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Company Name"
                        label-for="v-ticket-no"
                      >
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-ticket-no"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Account</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Username" label-for="v-username">
                        <b-form-input
                          :disabled="disableField"
                          id="v-username"
                          v-model="formData.renewal_id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Password" label-for="v-password">
                        <validation-provider
                          #default="{ errors }"
                          name="Password"
                          vid="password"
                          rules="password"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              :disabled="formData.id ? true : false"
                              id="v-password"
                              v-model="password"
                              :type="password1FieldType"
                              :state="errors.length > 0 ? false : null"
                              class="form-control-merge"
                              name="v-password"
                              placeholder="············"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="password1ToggleIcon"
                                @click="togglePassword1Visibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label-for="v-password_confirm"
                        label="Confirm Password"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Confirm Password"
                          vid="confirm_password"
                          rules="confirmed:password"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              :disabled="formData.id ? true : false"
                              id="v-password_confirm"
                              v-model="confirm_password"
                              :type="password2FieldType"
                              class="form-control-merge"
                              :state="errors.length > 0 ? false : null"
                              name="v-password_confirm"
                              placeholder="············"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="password2ToggleIcon"
                                @click="togglePassword2Visibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Other</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Description"
                        label-for="v-description"
                      >
                        <quill-editor
                          ref="quillEditorRefDescriptions"
                          v-model="contentDescriptions"
                          id="v-description"
                          :options="{ theme: 'snow' }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Remarks" label-for="v-remarks">
                        <quill-editor
                          ref="quillEditorRefRemarks"
                          v-model="contentRemarks"
                          id="v-remarks"
                          :options="{ theme: 'snow' }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Upload File">
                        <b-form-file
                          v-model="fileUpload"
                          placeholder="Choose a file..."
                          id="fileUpload"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="!formData.base64">
                    <b-col>
                      <b-form-group>
                        Uploaded File:
                        <span
                          class="text-primary cursor-pointer"
                          @click="onPreview"
                        >
                          {{ formData.filename }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="formData.base64">
                    <b-col class="text-center">
                      <b-form-group>
                        <div class="">
                          <b-img
                            v-if="fileType === 'image'"
                            height="250"
                            width="250"
                            thumbnail
                            fluid
                            :src="onViewThumbnail()"
                          />
                          <b-img
                            v-if="fileType === 'application'"
                            height="150"
                            width="150"
                            thumbnail
                            fluid
                            :src="require('@/assets/document.png')"
                          />
                          <video
                            height="250"
                            width="250"
                            controls
                            v-if="fileType === 'video'"
                          >
                            <source :src="onViewThumbnail()" />
                          </video>
                        </div>
                        <span>
                          {{ formData.filename }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <h2>Renewal Details</h2>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <RenewalDetails
                          :items="dataSourceDetails"
                          @on-duplicate="(v) => onDuplicate(v)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        @click="goBack()"
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="invalid"
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>

              <span v-if="formData.id">
                <b-tab title="Notes">
                  <Notes
                    ref="notesRef"
                    module="renewals"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Documents">
                  <Documents
                    ref="documentsRef"
                    module="renewals"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Comments"> </b-tab>
                <b-tab title="Logs"> </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import renewalHeadersApi from '@api/renewal_headers';
import statusesApi from '@api/statuses';
import categoriesApi from '@api/categories';
import suppliersApi from '@api/suppliers';
import { required } from '@validations';
import md5 from 'md5';
import RenewalDetails from './components/RenewalDetails.vue';

export default {
  name: 'RenewalManage',
  components: {
    RenewalDetails,
  },
  data: () => ({
    errors: null,
    required,
    activeTab: 0,
    dataSourceDetails: [],
    contentDescriptions: '',
    contentRemarks: '',
    previewFirstPurchaseDate: null,
    previewTerminationDate: null,
    previewOnDate: null,
    previewDueDate: null,
    previewPaymentDate: null,
    previewReminderDate: null,
    formData: {
      id: 0,
      company_id: null,
      refnum: null,
      vendor: null,
      product_type: null,
      serial_no: null,
      first_purchase_date: null,
      termination_date: null,
      due_date: null,
      on_date: null,
      renewal_status: null,
      method: null,
      reminder: null,
      notification_email: null,
      url: null,
      renewal_id: null,
      password: null,
      description: null,
      remarks: null,
      payment_amount: 0,
      payment_date: null,
      reminder_date: null,
      receipt_no: null,

      company_name: null,
      filename: null,
      base64: null,
    },
    fileUpload: null,
    fileType: null,
    password: null,
    renewalStatuses: [],
    productTypes: [],
    suppliers: [],
    confirm_password: null,
    password1FieldType: 'password',
    password2FieldType: 'password',
    watchFee: '0.00',
    options: {
      feeCleave: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        rawValueTrimPrefix: true,
        onValueChanged: (e) => {
          //
        },
      },
    },
  }),
  mounted() {
    const [date] = this.defaultDateTime().split('T');
    this.previewFirstPurchaseDate = date;
    this.previewTerminationDate = date;
    this.previewOnDate = date;
    this.previewDueDate = date;
    this.previewPaymentDate = date;
    this.previewReminderDate = date;

    this.loadSuppliers();
    this.loadProductTypes();
    this.loadSystemCode();

    if (this.$route.query.id) {
      this.loadRenewal();
    }
  },
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.notesRef.loadNotes();
      } else if (v === 2) {
        this.$refs.documentsRef.loadDocuments();
      }
    },
    fileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const { name, type } = file;
          this.formData.filename = name;
          this.formData.base64 = reader.result;
          this.fileType = type.split('/')[0];
        };
      }
    },
    watchFee(v) {
      let value = v !== '' && typeof v == 'string' ? v.replace(/,/g, '') : 0;
      this.formData.payment_amount = parseFloat(value);
    },
  },
  computed: {
    onUpdate: {
      get() {
        return this.$route.query.id ? 'Update' : 'Submit';
      },
    },
    chargeableType: {
      get() {
        return this.formData.chargeable_type === 'H' ? 'Hours' : 'Token';
      },
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    disableField: {
      get() {
        return false;
      },
    },
  },
  methods: {
    loadRenewal() {
      renewalHeadersApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const unixToDate = (date) => {
            if (date) {
              const [dateValue] = this.inputDateTimeFromUnix(date).split('T');

              if (dateValue) {
                return dateValue;
              }
            }
          };

          const { quill: qDescription } = this.$refs.quillEditorRefDescriptions;
          if (qDescription) {
            qDescription.root.innerHTML = data.description;
          }

          const { quill: qRemarks } = this.$refs.quillEditorRefRemarks;
          if (qRemarks) {
            qRemarks.root.innerHTML = data.remarks;
          }

          this.previewFirstPurchaseDate = unixToDate(data.first_purchase_date);
          this.previewTerminationDate = unixToDate(data.termination_date);
          this.previewOnDate = unixToDate(data.on_date);
          this.previewDueDate = unixToDate(data.due_date);
          this.previewPaymentDate = unixToDate(data.payment_date);
          this.previewReminderDate = unixToDate(data.reminder_date);

          this.watchFee = data.payment_amount.toString();
          this.formData = data;

          const items = [];

          if (data.details) {
            for (const item of data.details.data) {
              item.preview_due_date = this.unixToDate(item.due_date);
              item.preview_on_date = this.unixToDate(item.on_date);
              item.preview_payment_date = this.unixToDate(item.payment_date);
              item.preview_reminder_date = this.unixToDate(item.reminder_date);

              items.push(item);
            }
          }
          this.dataSourceDetails = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: "code='renewal:status'",
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          const [rs] = data.filter((x) => {
            return x.code === 'renewal:status';
          });
          if (rs) {
            this.renewalStatuses = rs.selections;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadProductTypes() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
      };

      categoriesApi
        .list(params)
        .then(({ data, meta }) => {
          const items = [];

          for (const item of data) {
            items.push({ label: item.name, value: item.name });
          }

          this.productTypes = items;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSuppliers() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
      };

      suppliersApi
        .list(params)
        .then(({ data, meta }) => {
          const items = [];

          for (const item of data) {
            items.push({ label: item.name, value: item.name });
          }

          this.suppliers = items;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      this.$refs.formRef.validate().then((success) => {
        if (success) {
          let app;

          this.formData.description = this.contentDescriptions;
          this.formData.remarks = this.contentRemarks;

          if (!this.formData.password && this.formData.id === 0) {
            this.formData.password = md5(this.password);
          }

          if (this.previewFirstPurchaseDate) {
            this.formData.first_purchase_date = this.inputDateTimeToUnix(
              this.previewFirstPurchaseDate
            );
          }

          if (this.previewTerminationDate) {
            this.formData.termination_date = this.inputDateTimeToUnix(
              this.previewTerminationDate
            );
          }

          if (this.previewDueDate) {
            this.formData.due_date = this.inputDateTimeToUnix(
              this.previewDueDate
            );
          }

          if (this.previewOnDate) {
            this.formData.on_date = this.inputDateTimeToUnix(
              this.previewOnDate
            );
          }

          if (this.previewPaymentDate) {
            this.formData.payment_date = this.inputDateTimeToUnix(
              this.previewPaymentDate
            );
          }

          if (this.previewReminderDate) {
            this.formData.reminder_date = this.inputDateTimeToUnix(
              this.previewReminderDate
            );
          }

          if (this.formData.id) {
            app = renewalHeadersApi.update(this.formData);
          } else {
            app = renewalHeadersApi.add(this.formData);
          }

          if (app) {
            app
              .then(({ data }) => {
                this.toastConfig();
                const url = `/pages/agreements/renewal/view?id=${data.id}`;
                this.$router.push(url);
              })
              .catch(() => {
                this.toastConfig('', 'error');
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    onDuplicate(item) {
      const { id } = item;
      renewalHeadersApi
        .duplicate(id)
        .then(({ data }) => {
          if (data) {
            this.loadRenewal();
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSelectCompany(event) {
      if (event) {
        const { id, company_name } = event;

        this.formData.company_id = parseInt(id);
        this.formData.company_name = company_name;
      }
    },
    onViewThumbnail() {
      if (this.formData.base64) {
        return this.formData.base64;
      } else {
        const params = {
          model: 'renewals',
          id: this.formData.detail_id,
          thumbnail: this.formData.filename,
        };

        return this.getImage(params);
      }
    },
    onPreview() {
      window.open(this.onViewThumbnail(), '_blank');
    },
    goBack() {
      this.$router.push('/pages/agreements/renewal');
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style lang="scss"></style>
